import {css, PropertyValues} from 'lit';
import {property} from 'lit/decorators.js';
import {TextField} from '@material/mwc-textfield';

import {applyDenseStylesheet, disableDenseStylesheet} from "./dense-fix";

export class CbarTextfield extends TextField {
  static styles = [
    ...TextField.styles,
    css`
      :host([hidden]) {
        display: none;
      }

      mwc-textfield {
        width: 100%;
        --mdc-text-field-filled-border-radius: var(--cbar-text-field-filled-border-radius);
        --mdc-text-field-idle-line-color: var(--cbar-text-field-idle-line-color, var(--cbar-sys-color-primary));
        --mdc-text-field-hover-line-color: var(--cbar-text-field-hover-line-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-disabled-line-color: var(--cbar-text-field-disabled-line-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-outlined-idle-border-color: var(--cbar-text-field-outlined-idle-border-color, var(--cbar-sys-color-primary));
        --mdc-text-field-outlined-hover-border-color: var(--cbar-text-field-outlined-hover-border-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-outlined-disabled-border-color: var(--cbar-text-field-outlined-disabled-border-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-fill-color: var(--cbar-text-field-fill-color, var(--cbar-sys-color-surface-container-highest, transparent));
        --mdc-text-field-disabled-fill-color: var(--cbar-text-field-disabled-fill-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-ink-color: var(--cbar-text-field-ink-color, var(--cbar-sys-color-on-surface, black));
        --mdc-text-field-label-ink-color: var(--cbar-text-field-label-ink-color, var(--cbar-sys-color-primary, #004CA2));
        --mdc-text-field-disabled-ink-color: var(--cbar-text-field-disabled-ink-color, var(--cbar-sys-color-on-surface-variant, grey));
      }
    `
  ];

  @property({type: Boolean})
  dense = false;

  @property({type: Boolean})
  hideErrorsOnFocus = false;

  constructor() {
    super();

    // Recalculate layout when the textfield becomes visible.
    // Fixes https://github.com/material-components/material-web/issues/2915
    this.respondToVisibility();
  }

  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    if (changedProperties.has('dense')) {
      requestAnimationFrame(() => this.dense? applyDenseStylesheet(this) : disableDenseStylesheet(this));
    }
  }

  protected onInputFocus() {
    super.onInputFocus();

    if (this.hideErrorsOnFocus) {
      // @ts-ignore
      this.mdcFoundation.styleValidity(true);
    }
  }

  respondToVisibility() {
    const options = {
      root: document.body,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Re-calculate layout to ensure label on outlined textfield
          // is properly displayed
          this.layout();
        }
      });
    }, options);

    observer.observe(this);
  }
}
